@import url("https://fonts.googleapis.com/css2?family=Bilbo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.canvas-frame {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}

.canvas-frame-2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}

.needle__image {
  position: absolute;
  left: 20px;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url("/public/assets/images/white-needle.png");
  cursor: pointer;
  background-size: contain;
}

.logo-main {
  height: 70px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@media (max-width: 768px) {
  canvas.canvas-frame {
    width: 80%;
    height: 300px;
  }

  .canvas-div {
    height: 350px;
  }

  canvas.canvas-frame-2 {
    width: 80%;
    height: 70px;
  }

  .canvas-div_2 {
    height: 100px;
  }

  canvas.canvas-frame__clock {
    width: 300px;
    height: 300px;
  }

  canvas.canvas-frame__clock-2 {
    width: 70px;
    height: 70px;
  }

  .input__text-container {
    font-size: 12px;
  }

  .needle__image {
    left: 0;
  }

  .logo-main {
    height: 45px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  canvas.canvas-frame {
    width: 80%;
    height: 350px;
  }

  .canvas-div {
    height: 400px;
  }

  canvas.canvas-frame-2 {
    width: 80%;
    height: 120px;
  }

  .canvas-div_2 {
    height: 150px;
  }

  canvas.canvas-frame__clock {
    width: 300px;
    height: 300px;
  }

  canvas.canvas-frame__clock-2 {
    width: 100px;
    height: 100px;
  }

  .margin__table-view {
    margin-top: 53px;
  }

  .input__text-container {
    font-size: 12px;
  }

  .needle__image {
    left: 0;
  }

  .logo-main {
    height: 55px;
  }
}

@media (min-width: 992px) {
  canvas.canvas-frame {
    width: 400px;
    height: 450px;
  }

  .canvas-div {
    height: 500px;
  }

  canvas.canvas-frame-2 {
    width: 140px;
    height: 170px;
  }

  .canvas-div_2 {
    height: 200px;
  }

  canvas.canvas-frame__clock {
    width: 450px;
    height: 450px;
  }

  canvas.canvas-frame__clock-2 {
    width: 160px;
    height: 160px;
  }

  .input__text-container {
    font-size: 18px;
  }

  .needle__image {
    left: 0px;
  }

  .logo-main {
    height: 70px;
  }
}